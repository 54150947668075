import React, {useState, useEffect} from "react";
import NewsPageBanner from "../components/newspage-banner.jpg"
import { Link, graphql,navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import  'bootstrap/dist/css/bootstrap.min.css';
import loadable from '@loadable/component'
import {SearchBar,unFlattenResults} from '../components/SearchNews'
import { useFlexSearch } from 'react-use-flexsearch';
import { window } from 'browser-monads';

const Footer = loadable(() => import('../components/Footer'))
const Nav = loadable(() => import ('../components/Nav'))


function NewsYear(newsyear) {
  const location = useLocation();
  const dateParam = location.pathname.slice(6)
  const index=newsyear.data.localSearchPages.index
  const store=newsyear.data.localSearchPages.store
  const { search } = window.location;
  const query = new URLSearchParams(search).get('s');
  const [searchQuery, setSearchQuery] = useState(query || '');
  const results = useFlexSearch(searchQuery, index, store);
  let newsData = searchQuery ? unFlattenResults(results) : newsyear.data.allMarkdownRemark.edges;
  const [newsList, setNewsList] = useState(newsData ? [newsData.filter( element => element.node.frontmatter.date.slice(0, 4) === `${dateParam}`)] : [{}])
  const count = {}

  var datesUnique = []
  var dates = []
  newsData.map(news => {
    dates.push(news?.node?.frontmatter?.date?.slice(0, 4))
  })
  datesUnique = [...new Set(dates)]
  dates.forEach(element => {
    count[element] = (count[element] || 0) + 1;
  });
  console.log(newsList[0], "console news")
  return (
    <>
      <Nav/>
      <section className="min-height-400px cover-background top-space-verum" style={{backgroundImage: `url(${NewsPageBanner})`}}>
        <div className="container">
          <div className="row">
              <div className="col-12 col-lg-8">
                <h3 className="inner-banner-title text-white">Search, Explore, Discover</h3>
              </div> 
              <div className="col-12 col-lg-4" data-wow-delay="0.2s"></div>
          </div>
        </div>
      </section>
      <section className="padding-70px-top padding-30px-bottom">
        <div className="container">
          <div className="row justify-content-center">
              <div className="col-12 col-lg-8 text-center">
                <h3 className="title-section alt-font text-dark-gray letter-spacing-1">News</h3>
              </div>
          </div>
        </div>
      </section>
      <section className="no-padding-top padding-70px-bottom hover-option4 blog-post-style3">
        <div className="container">
          <div className="row">
            <main className="col-12 col-lg-9 right-sidebar md-margin-60px-bottom sm-margin-40px-bottom pl-0 md-no-padding-right">
            {newsList.length && newsList.length < newsData.length ? (
              <div className="row">
                {newsList[0].map(newsPage => {
                  return (
                    <div className="col-12 col-lg-4 col-md-6 grid-item margin-30px-bottom text-center text-md-left">
                      <div className="blog-post bg-light-gray inner-match-height" style={{background: "#e2e2e2", height: "500px"}}>
                        <div className="blog-post-images overflow-hidden position-relative">
                          <Link to={`/News/${newsPage && newsPage.node && newsPage.node.id? newsPage.node.id : ''}`}>
                            <img width="320px" height="200px" src={newsPage && newsPage.node && newsPage.node.frontmatter && newsPage.node.frontmatter.thumbnail ? newsPage.node.frontmatter.thumbnail : ''} alt="" />
                            <div className="blog-hover-icon"><span className="text-extra-large font-weight-300">+</span></div>
                          </Link>
                        </div>
                        <div className="post-details padding-25px-all md-padding-20px-all">
                          <Link to={`/News/${newsPage && newsPage.node && newsPage.node.id ? newsPage.node.id : ''}`} 
                            className="post-title text-extra-dark-gray width-100 d-block lg-width-100 margin-15px-bottom"
                            style={{fontSize: "18px", lineHeight: "24px", fontWeight: "600", color: "#4e4f50"}}
                          >{newsPage && newsPage.node && newsPage.node.frontmatter && newsPage.node.frontmatter.title? newsPage.node.frontmatter.title : ''}</Link>
                          <div className="entry-content"       dangerouslySetInnerHTML={{__html: newsPage && newsPage.node && newsPage.node.rawMarkdownBody ? newsPage.node.rawMarkdownBody.substring(0, 120) + "..." : ''}}/>

                        </div>
                      </div>
                    </div>   
                  )
                })}   
              </div>
            ) : (
              <div className="row">
                {newsData.map((newsBlog) => {
                  return (
                    <div className="col-12 col-lg-4 col-md-6 grid-item margin-30px-bottom text-center text-md-left">
                      <div className="blog-post bg-light-gray inner-match-height" style={{background: "#e2e2e2", height: "500px"}}>
                        <div className="blog-post-images overflow-hidden position-relative">
                          <Link to={`/News/${newsBlog.node.id}`}>
                            <img width="320px" height="200px" src={newsBlog.node.frontmatter.thumbnail} alt="" />
                            <div className="blog-hover-icon"><span className="text-extra-large font-weight-300">+</span></div>
                          </Link>
                        </div>
                        <div className="post-details padding-25px-all md-padding-20px-all">
                          <Link to={`/News/${newsBlog.node.id}`} 
                            className="post-title text-extra-dark-gray width-100 d-block lg-width-100 margin-15px-bottom"
                            style={{fontSize: "18px", lineHeight: "24px", fontWeight: "600", color: "#4e4f50"}}
                          >{newsBlog.node.frontmatter.title}</Link>
                          <div className="entry-content"       dangerouslySetInnerHTML={{__html: newsBlog.node.rawMarkdownBody.substring(0, 120) + "..."}}/>
                        </div>
                      </div>
                    </div>   
                  )
                })}   
              </div>
            )}
            </main>

            <aside className="col-12 col-lg-3 float-right">
            <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />

              <div className="bg-light-gray">
                <div className="margin-20px-bottom font-weight-600 aside-title"><span>Archive</span></div>
                {datesUnique?.length > 0 ? (
                  <ul className="list-style-6 margin-20px-bottom">
                    {/* {datesUnique.map((date) => {
                      console.log(date, "show date") */}
                      {/* return ( */}
                        <>
                    <Link to="/News/2022"> <li><a href="blog-grid.html">2022</a><span>5</span></li></Link>
                    <Link to="/News/2021"><li><a href="blog-grid.html">2021</a><span>11</span></li></Link>
                    <Link to="/News/2020"> <li><a href="blog-grid.html">2020</a><span>11</span></li></Link>
 </>
                      {/* ) */}
                    {/* })} */}
                  </ul>   
                ) : ""
                }
              </div>
            </aside>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}
export default NewsYear;

export const BlogYearQuery = graphql`
query NewsYearQuery {
  localSearchPages {
    index
    store
  }
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/News/)/"}}) {
    edges {
      node {
        frontmatter {
          date
          thumbnail
          title
          video
          
        }
        id
        rawMarkdownBody
      }
    }
  }
}
`